<template>
    <div class="body-container">
        <BannerSection
                       title="Cómo empeñar los papeles de tu auto<br class='d-none d-lg-block'/>en México: Paso a paso" />
        <PerksComponent></PerksComponent>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <p class="mb-0">
                        En momentos donde la necesidad de liquidez se hace presente de manera urgente, empeñar los
                        papeles de tu carro emerge como una alternativa viable y rápida. A diferencia de empeñar el
                        vehículo completo, esta opción permite acceder a financiamiento manteniendo el uso de tu auto.
                        En este artículo, explicaremos cómo puedes empeñar los papeles de tu carro en México, destacando
                        los requisitos necesarios, los beneficios de esta modalidad y <b>dónde puedo empeñar los papeles
                            de
                            mi carro</b> de manera segura y confiable.
                    </p>
                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">Requisitos para empeñar los papeles de tu carro
                        </h2>
                    </span>

                    <p class="mb-0">
                        Antes de adentrarnos en el proceso, es crucial conocer los requisitos básicos que necesitarás:
                    </p>
                    <br>
                    <ul>
                        <li>
                            <p class="mb-0 bold-text">
                                Documentación completa del vehículo
                            </p>
                            <span> Debes contar con la factura original de tu carro, que pruebe tu propiedad sobre el
                                mismo.</span>
                        </li>

                        <br>

                        <li>
                            <p class="mb-0 bold-text">
                                Identificación oficial vigente:
                            </p>
                            <span>INE, pasaporte o cualquier documento oficial que acredite tu identidad.</span>
                        </li>
                        <br>

                        <li>
                            <p class="mb-0 bold-text">
                                Comprobante de domicilio reciente:
                            </p>
                            <span>Para verificar tu lugar de residencia actual.</span>
                        </li>
                        <li>
                            <p class="mb-0 bold-text">
                                Comprobante de ingresos:
                            </p>
                            <span>Algunos prestamistas podrían solicitarlo para evaluar tu capacidad de pago.</span>
                        </li>
                    </ul>

                    <br>
                </div>
            </div>
        </div>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">Beneficios de empeñar papeles de carro en vez del vehículo</h2>
                    </span>
                    <p>Empeñar los documentos de tu carro tiene ventajas significativas, te las enumeramos a
                        continuación: </p>
                    <br>
                    <ol>
                        <li>
                            <p class="mb-0">
                                <span class="bold-text">Continúas utilizando tu vehículo:
                                </span> A diferencia del empeño tradicional, no necesitas dejar tu carro en el lote del
                                prestamista.

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="bold-text">Proceso rápido y sencillo
                                </span>La evaluación y el desembolso del préstamo suelen ser más ágiles.

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                Puedes acceder a formas de financiamiento que entidades tradicionales no podría
                                ofrecerte.

                            </p>
                        </li>
                        <br>
                    </ol>
                    <br>
                </div>

            </div>

        </div>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">Lista de lugares confiables para empeñar papeles en México</h2>
                    </span>
                    <p>En <a
                           href="https://www.gob.mx/profeco/articulos/casas-de-empeno#:~:text=Actualmente%2C%20de%20acuerdo%20con%20el,operar%2C%207%2C653%20casas%20de%20empe%C3%B1o.&text=Si%20necesitas%20sanear%20tus%20finanzas,cobros%20excesivos%20y%20cl%C3%A1usulas%20abusivas.">2020
                            el Gobierno de México</a> tenía registradas más de 7000 casas de empeño. Tal y como apuntan,
                        antes de decantarse por un prestamista u otro es fundamental corroborar su reputación y que sus
                        condiciones no sean abusivas. Es recomendable que investigues y compares diferentes opciones
                        para encontrar la que mejor se ajuste a tus necesidades. </p>
                    <p><b>Ibancar</b>, ofrece confiabilidad y transparencia, avalada por su experiencia de más de 6 años
                        y la satisfacción de sus clientes. Su servicio es transparente y alejado de cláusulas abusivas.
                    </p>
                    <br>
                    <br>
                </div>

            </div>

        </div>

        <div style="background-color: rgba(255, 165, 0, 0.04); padding: 1.5rem 0rem; margin: 1.5rem 0rem;">
            <div class="ibancar-election">
                <p>¿Por qué elegir Ibancar?</p>
            </div>
            <BeneficiosIbancarCarousel />
        </div>
        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <br>
                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">Paso a paso para empeñar los papeles de tu carro</h2>
                    </span>
                    <p>A continuación hemos creado un paso a paso, para que encuentres el mejor prestamista para empeñar
                        los papeles de tu carro en México: </p>
                    <br>
                    <ul>
                        <li>
                            <p class="mb-0">
                                <span class="bold-text">Primer paso, investiga y elige el mejor prestamista:
                                </span> Busca opiniones, compara tasas de interés y elige la opción más segura y
                                conveniente.

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="bold-text">Segundo paso, prepara tu documentación:
                                </span>Asegúrate de tener todos los documentos requeridos completos y actualizados.

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="bold-text">Tercer paso, solicita tu préstamo:
                                </span>Acude al prestamista de tu elección con tu documentación y solicita el empeño de
                                los papeles de tu carro.

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="bold-text">Cuarto paso, evaluación y oferta:
                                </span> El prestamista evaluará la documentación y el estado de tu carro para hacerte
                                una oferta de préstamo

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="bold-text">Quinto paso, firma del contrato:
                                </span> Si estás de acuerdo con los términos, firmarás un contrato donde se especifiquen
                                todas las condiciones del préstamo.

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="bold-text">Sexto paso, recibe tu dinero:
                                </span> Una vez firmado el contrato, recibirás el monto acordado.

                            </p>
                        </li>
                        <br>
                    </ul>
                    <br>
                    <p>Empeñar los papeles de tu carro puede ser una ruta eficaz para sortear esos apuros económicos de
                        forma rápida, dándote la gran ventaja de poder seguir rodando en tu vehículo mientras pones en
                        orden tus finanzas. Es clave que te tomes el tiempo para investigar a conciencia <b>dónde puedo
                            empeñar los papeles de mi carro</b>, que tengas a mano toda tu documentación necesaria y que
                        entiendas a la perfección los términos y condiciones del contrato que vas a firmar. Esta
                        preparación te ayudará a asegurar una experiencia sin contratiempos y sin sorpresas
                        desagradables, permitiéndote enfocarte en lo que realmente importa: recuperar tu estabilidad
                        financiera con la tranquilidad de que estás tomando una decisión informada y segura.</p>
                    <p>En Ibancar sabemos que lo desconocido abruma, y es por esta razón que nuestro servicio es
                        personalizado y se adapta perfectamente a tus necesidades, además respaldado por su
                        confiabilidad y su transparencia. Así que si a día de hoy, estás pasando por preocupaciones
                        financieras, no dudes en contactar con nosotros, ¡estaremos encantados de ayudarte! </p>
                    <br>
                </div>

            </div>

        </div>
        <SolicitaloBannerS2 />
    </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import BannerSection from '@/components/Landings/BannerSection.vue';
import BeneficiosIbancarCarousel from '@/components/PrestamoGarantiaAuto/BeneficiosIbancarCarousel.vue';
import SolicitaloBannerS2 from '@/components/Landings/SolicitaloBannerS2.vue';
import PerksComponent from '../components/PerksComponent.vue';


export default {
    name: 'ComoEmpenarLosPapelesDeTuAuto',
    components: {
        BannerSection,
        BeneficiosIbancarCarousel,
        SolicitaloBannerS2,
        PerksComponent
    },
    setup() {
        useMeta(
            {
                title: 'Dónde empeñar los papeles de tu carro en México: Tu guía completa | Ibancar',
                meta: [
                    {
                        name: 'robots',
                        content: 'index'
                    },
                    {
                        name: 'description',
                        content: 'Explora las opciones más confiables para empeñar los papeles de tu carro en México. Obtén préstamos rápidos y seguros siguiendo nuestra guía detallada.'
                    },
                    {
                        name: 'keywords',
                        content: 'donde puedo empeñar los papeles de mi carro'
                    }
                ],
                link: [
                    // { href: 'https://ibancar.com/prestamos-con-aval-de-coche-sin-cambiar-de-titular', rel: 'alternate', hreflang: 'es-es' },
                    { href: 'https://ibancar.mx/como-empenar-los-papeles-de-tu-auto-en-mexico', rel: 'alternate', hreflang: 'es-MX' }
                ]
            }
        )
    },

    data() {
        return {
            ventajas: [
                {
                    image: 'image12.svg',
                    title: 'Ejecutivo personalizado',
                },
                {
                    image: 'solicitud-en-linea.svg',
                    title: 'Proceso simple sin salir de casa',
                },

                {
                    image: 'prestamos-48-hrs.svg',
                    title: 'Hasta $120,000 en 24H',
                },
                {
                    image: 'proceso-simple.svg',
                    title: 'Aceptamos personas en Buró.',
                }

            ]
        }
    }
}
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';


.body-container {
    margin-top: 4.5rem;
    min-height: calc(100vh - 115px);
}

@include media-breakpoint-up(md) {
    .body-container {
        margin-top: 8.5rem;
    }
}

.ibancar-election {
    margin: 1rem 2rem 0rem 2rem;

    p {
        color: #004680;
        text-align: center;
        font-family: 'Montserrat-Bold';
        font-size: 1.3rem;
        font-style: normal;
        line-height: normal;
        margin: 1.5rem 0rem;
    }
}

.text-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    text-align: justify;
}

.regular-text {
    font-family: "Montserrat";
    font-size: 20px;
    line-height: 30px;
}

.title {
    font-size: 25px;
    line-height: 30px;
}

.bold-text {
    font-family: "Montserrat-Bold";
}

.text-orange {
    color: $orange;
}

.text-blue {
    color: $blue;
}

.text-black {
    color: $black;
}

.text-underline {
    text-decoration: underline;
}


.btn-solicitar {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

// Image
.image {
    bottom: 0;
    right: 0;
    width: 30%;
    z-index: -1;
}

@include media-breakpoint-down(md) {
    .text-container {
        width: 90%;
    }

    .regular-text {
        line-height: 1.5;
    }

    ul {
        padding-inline-start: 20px;
    }

    ol {
        padding-inline-start: 20px;
    }
}

@include media-breakpoint-between(md, lg) {
    .regular-text {
        font-size: 16px;
    }

    .title {
        font-size: 28px;
        line-height: 35px;
    }

    .subtitle {
        font-size: 16px;
    }
}

@include media-breakpoint-down(sm) {
    .title {
        font-size: 20px;
        line-height: 25px;
    }

    .subtitle {
        font-size: 16px;
        line-height: 20px;
    }

    .regular-text {
        font-size: 16px !important;
    }

    .section-container {
        margin-top: 1.5rem;
    }
}
</style>